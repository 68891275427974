import { createContext, useContext } from 'react';
import { themeLight } from '../../themes';

const ThemeContext = createContext({
  theme: themeLight,
  changeTheme: () => {},
});

export const useTheme = () => useContext(ThemeContext);

export default ThemeContext;
